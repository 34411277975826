import React, { useEffect } from "react"
// import { useNavigate } from "react-router-dom"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export const ErrorAlert = () => {
  // const nav = useNavigate()
  const notifyError = errorMessage => toast.error(errorMessage)

  useEffect(() => {
    window.customErrorCallback = (errorObject, status) => {
      if (status === 401) {
        localStorage.clear()
        // return nav("/login")
      }
      notifyError(errorObject)
    }
  }, [])

  return (
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      type="error"
    />
  )
}
