import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter, useLocation, useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import AppContext from '../../Context';
import { secondaryNavs } from './SecondaryNavs';
import { ReactComponent as RedDot } from '../../../src/assets/svg/redDot.svg';
import { ReactComponent as Notification } from '../../../src/assets/svg/notification.svg';
import { getNotificationsCount } from '../../services/authService';

const Navbar = (props) => {
    const context = useContext(AppContext);
    const location = useLocation();
    const nav = useHistory();
    const secondaryNav = secondaryNavs.find((item) => location.pathname.includes(item.path));
    const user = context?.user;
    const [unreadCount, setUnreadCount] = useState(null);
    const toggleOffcanvas = () => {
        document.querySelector('.sidebar-offcanvas').classList.toggle('active');
    };

    const onLogoutUser = () => {
        localStorage.clear();
        context && context.setUser(null);
        props.history.push('/user-pages/choice');
    };

    useEffect(() => {
        getNotificationsCount()
            .then((response) => setUnreadCount(response.data?.unread_count))
            .catch((error) => console.log(error));
    }, []);

    return (
        <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
            <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
                <Link className="navbar-brand brand-logo" to="/">
                    <img src={user?.profile?.photo || require('../../assets/images/pna_logo.png')} style={{ resizeMode: 'contain', width: 80, height: 80, marginTop: 0 }} alt="logo" />
                </Link>
                <Link className="navbar-brand brand-logo-mini" to="/">
                    <img src={require('../../assets/images/logo-mini.svg')} alt="logo" />
                </Link>
            </div>
            {secondaryNav?.path ? (
                <div className="navbar-menu-wrapper d-flex align-items-stretch" style={{ backgroundColor: '#719275' }}>
                    <div style={{ position: 'relative', backgroundColor: '#719275', width: '100%', justifyContent: 'center', alignItems: 'flex-end', display: 'flex' }}>
                        <div
                            onClick={() => nav.goBack()}
                            style={{ position: 'absolute', left: '0', top: '50%', transform: 'translateY(-50%)', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                            <img src={require('../../assets/images/arrow_backward.png')} style={{ height: '1.2rem' }} alt="logo" />
                            <h4 style={{ color: '#fff', marginLeft: '0.5rem', marginBottom: '0', textTransform: 'uppercase' }}>{secondaryNav.subTitle}</h4>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <Link className="navbar-brand brand-logo" to="/" style={{ display: 'flex', justifyContent: 'center' }}>
                                <img src={user?.profile?.photo || require('../../assets/images/pna_logo.png')} style={{ resizeMode: 'contain', width: 80, height: 80, marginTop: 30 }} alt="logo" />
                            </Link>
                            <h4 className="card-title text-light">{secondaryNav.title}</h4>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="navbar-menu-wrapper d-flex align-items-stretch">
                    <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
                        <span className="mdi mdi-menu"></span>
                    </button>
                    {/* <div className="search-field d-none d-md-block">
                        <form className="d-flex align-items-center h-100" action="#">
                            <div className="input-group" style={{ borderRadius: '2rem' }}>
                                <div className="input-group-prepend bg-transparent">
                                    <i className="input-group-text border-0 mdi mdi-magnify"></i>
                                </div>
                                <input type="text" style={{ backgroundColor: '#eef0fa', outline: 'none', border: 'none' }} placeholder="Search" />
                            </div>
                        </form>
                    </div> */}
                    <ul className="navbar-nav navbar-nav-right">
                        <li class="nav-item  dropdown d-none d-md-block">
                            <div className="position-relative mr-2 cursor-pointer" onClick={() => nav.push('/notifications')}>
                                <Notification width={20} height={20} fill="#fff" title="Notification" onClick={() => {}} />
                                {unreadCount > 0 && (
                                    <RedDot style={{ position: 'absolute', top: '-0.25rem', right: '-0.25rem' }} width={10} height={10} fill="#fff" title="Notification" onClick={() => {}} />
                                )}
                            </div>
                        </li>
                        <li className="nav-item nav-profile nav-language">
                            <Dropdown alignRight>
                                <Dropdown.Toggle className="nav-link count-indicator">
                                    <div className="nav-profile-img">
                                        <img src={require('../../assets/svg/icon-person.svg')} alt="profile" />
                                    </div>
                                    <div className="nav-profile-text">
                                        <p className="mb-1 text-black">
                                            <Trans>{user?.type === 'Super Admin' ? 'Admin' : user?.name}</Trans>
                                        </p>
                                    </div>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className="preview-list navbar-dropdown">
                                    <div className="p-3 text-center bg-primary">
                                        <img style={{ backgroundColor: 'white' }} className="img-avatar img-avatar48 img-avatar-thumb" src={require('../../assets/svg/icon-person.svg')} alt="" />
                                    </div>
                                    <div className="p-2">
                                        <Dropdown.Item className="dropdown-item d-flex align-items-center justify-content-between" onClick={() => nav.push(`/my-profile/${user?.id}`)}>
                                            <span>
                                                <Trans>My Profile</Trans>
                                            </span>
                                            <i className="mdi mdi-user ml-1"></i>
                                            <Link className="dropdown-item" to={`/my-profile/${user?.id}`}></Link>
                                        </Dropdown.Item>
                                        <Dropdown.Item className="dropdown-item d-flex align-items-center justify-content-between" onClick={onLogoutUser}>
                                            <span>
                                                <Trans>Log Out</Trans>
                                            </span>
                                            <i className="mdi mdi-logout ml-1"></i>
                                            <Link className="dropdown-item" to="/user-pages/choice">
                                                {' '}
                                            </Link>
                                        </Dropdown.Item>
                                    </div>
                                </Dropdown.Menu>
                            </Dropdown>
                        </li>
                    </ul>

                    <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
                        <span className="mdi mdi-menu"></span>
                    </button>
                </div>
            )}
        </nav>
    );
};

export default withRouter(Navbar);
