import React, { createContext, useContext } from 'react';
import { toast } from 'react-toastify';

import { screenList } from './constants';
const UserAccessContext = createContext();

const UserAccessProvider = ({ children }) => {
    const notifySuccess = (successMessage) => toast.success(successMessage,{
        className:'react-toast-message',
    });
    const notifyError = errorMessage => toast.error(errorMessage)

    const userHasRWAccess = (screenName) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const type = user?.type;
        const result = screenList.find(({ name }) => name === screenName);
        if(!result?.access){
            return false
        }
        const { RW } = result.access;
        return RW.includes(type);
    };

    const userHasRAccess = (screenName) => {
        const user = JSON.parse(localStorage.getItem('user'));
        const type = user?.type;
        const result = screenList.find(({ name }) => name === screenName);
        const { R } = result.access;
        return R.includes(type);
    };

    return (
        <UserAccessContext.Provider
            value={{
                notifySuccess,
                notifyError,
                userHasRWAccess,
                userHasRAccess
            }}>
            {children}
        </UserAccessContext.Provider>
    );
};

export const useUserAccessContext = () => useContext(UserAccessContext);

export default UserAccessProvider;
