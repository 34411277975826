import { initializeApp } from 'firebase/app';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';

var firebaseConfig = {
    apiKey: 'AIzaSyDD4sL8gZjZFH_XAJh2KL9LzeovEgIc-J0',
    authDomain: 'pnaproject-296cb.firebaseapp.com',
    projectId: 'pnaproject-296cb',
    storageBucket: 'pnaproject-296cb.appspot.com',
    messagingSenderId: '143613058587',
    appId: '1:143613058587:web:f30cb23f8d0cb00cdfe2be',
    measurementId: 'G-NR3J2L23BY'
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export const requestForToken = async () => {
    try {
        const currentToken = await getToken(messaging, { vapidKey: 'BB4-XIqR2--JsofybaUi3pwt5VfnEXT2abFj98T09QCKwcy4Kz8OlLL_TaXd1tBlbCU2h4IhFiFpW4pwprfNYiM' });
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            // setTokenFound(true);
        } else {
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
    }
};
export const onMessageListener = () =>
    new Promise(resolve => {
        onMessage(messaging, payload => {
            resolve(payload);
        });
    });
